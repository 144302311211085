<template>
    <div>
        <Header>
            <template v-slot:header-title>
                Legal
            </template>
        </Header>
        <section class="legal-section">
            <div class="container">
                <div class="legal-wrapper">
                    <!-- Menu -->
                    <div class="legal-menu">
                        <div class="menu-item"
                             :class="{ 'active': showTermsOfService }"
                             @click="SetLegalItemActiveState('terms-of-service')">
                             Terms of Service
                        </div>
                        <div class="menu-item"
                             :class="{ 'active': showPrivacyPolicy }"
                             @click="SetLegalItemActiveState('privacy-policy')">
                             Privacy Policy
                        </div>
                        <!-- <div class="menu-item"
                             :class="{ 'active': showCookiePolicy }"
                             @click="SetLegalItemActiveState('cookie-policy')">
                             Cookie Policy
                        </div> -->
                    </div>
                    <!-- Content -->
                    <div class="legal-content-wrapper">
                        <!-- Terms of Service -->
                        <div class="legal-item" v-show="showTermsOfService">
                            <div class="flex download-modified-wrapper">
                                <div class="icon-with-text pointer" id="download-pdf" @click.prevent="DownloadPDF('Terms and Conditions June 7 2022.pdf')">
                                    <i class="bi bi-download"></i>
                                    Download PDF
                                </div>
                                <div class="icon-with-text" id="last-modified">
                                    <i class="bi bi-calendar"></i>
                                    Last Modified: June 07 2022
                                </div>
                            </div>
                            <h1>Terms of Service</h1>
                            <div class="text-block">
                                <p>By Using Our Site You Accept These Terms and Conditions</p>
                                <p>Please read these Terms and Conditions carefully and ensure that you understand them before using Our Site. These Terms and Conditions, together with any other documents referred to herein, set out the terms of use governing your use of this website, <a href="https://illapa.cloud" class="link" target="_blank">https://illapa.cloud</a> (“Our Site”). It is recommended that you print a copy of these Terms and Conditions for your future reference.</p>
                                <p>These Terms and Conditions were last updated on 7th June 2022.</p>
                                <p>Your agreement to comply with these Terms and Conditions is indicated by your use of Our Site. If you do not agree to these Terms and Conditions, you must stop using Our Site immediately.</p>
                                <p>
                                    The following document also applies to your use of Our Site:
                                    <ul>
                                        <li>Our <span class="link pointer" @click="SetLegalItemActiveState('privacy-policy')">Privacy Policy</span>. This is also referred to below in <a href="#tos-15" class="link">Part 15</a>.</li>
                                    </ul>
                                </p>
                            </div>
                            <div class="text-block" id="tos-1">
                                <h2>1. Definitions and Interpretation</h2>
                                <p>1.1 In these Terms and Conditions, unless the context otherwise requires, the following expressions have the following meanings:</p>
                                <p>“Content” means any, and all text, images, audio, video, scripts, code, software, databases, and any other form of information capable of being stored on a computer that appears on, or forms part of, Our Site; and</p>
                                <p>“We/Us/Our” means Illapa Cloud Limited.</p>
                            </div>
                            <div class="text-block" id="tos-2">
                                <h2>2. Information About Us</h2>
                                <p>2.1 Our Site is operated by Illapa Cloud Limited. We are a limited company registered in England and Wales under company number 10890314. Our registered address is 11 Castle Hill, Maidenhead, Berkshire SL6 4AA and Our business address is 1st Floor, Building 3, Concorde Park, Concorde Road, Maidenhead, Berkshire SL6 4BY.</p>
                                <p>2.2 Our VAT number is GB 274 4676 70.</p>
                            </div>
                            <div class="text-block" id="tos-3">
                                <h2>3. How to Contact Us</h2>
                                <p>To contact Us, please email Us at <a href="mailto:info@illapa.cloud" class="link">info@illapa.cloud</a> or telephone Us on <a href="tel:+44 (0)203 917 1110" class="link">+44 (0)203 917 1110</a>.</p>
                            </div>
                            <div class="text-block" id="tos-4">
                                <h2>4. Access to Our Site</h2>
                                <p>4.1 Access to Our Site is free of charge.</p>
                                <p>4.2 It is your responsibility to make the arrangements necessary in order to access Our Site.</p>
                                <p>4.3 Access to Our Site is provided on an “as is” and on an “as available” basis. We may suspend or discontinue Our Site (or any part of it) at any time. We do not guarantee that Our Site will always be available or that access to it will be uninterrupted.</p>
                            </div>
                            <div class="text-block" id="tos-5">
                                <h2>5. Changes to Our Site</h2>
                                <p>We may alter and update Our Site (or any part of it) at any time. If We make any significant alterations to Our Site (or any part of it), We will try to give you reasonable notice of the alterations.</p>
                            </div>
                            <div class="text-block" id="tos-6">
                                <h2>6. Changes to these Terms and Conditions</h2>
                                <p>6.1 We may alter these Terms and Conditions at any time. If We do so, details of the changes will be highlighted at the top of this page. As explained above, your use of Our Site constitutes your acceptance of these Terms and Conditions. Consequently, any changes made to these Terms and Conditions will apply to your use of Our Site the first time you use it after the changes have been implemented. You are therefore advised to check this page every time you use Our Site.</p>
                                <p>6.2 If any part of the current version of these Terms and Conditions conflicts with any previous version(s), the current version shall prevail unless We explicitly state otherwise.</p>
                            </div>
                            <div class="text-block" id="tos-7">
                                <h2>7. How You May Use Our Site and Content (Intellectual Property Rights)</h2>
                                <p>7.1 All Content included on Our Site and the copyright and other intellectual property rights in that Content belongs to or has been licensed by Us, unless specifically labelled otherwise. All Content is protected by applicable United Kingdom and international intellectual property laws and treaties.</p>
                                <p>7.2 You may access, view, and use Our Site in a web browser (including any web browsing capability built into other types of software or app) and you may download Our Site (or any part of it) for caching (this usually occurs automatically).</p>
                                <p>7.3 You may print copies and download extracts of any page(s) from Our Site.</p>
                                <p>7.4 Our status as the owner and author of the Content on Our Site (or that of identified licensors, as applicable) must always be acknowledged.</p>
                                <p>7.5 Nothing in these Terms and Conditions limits or excludes the provisions of Chapter III of the Copyright, Designs and Patents Act 1988, ‘Acts Permitted in Relation to Copyright Works’, which provides exceptions allowing certain uses of copyright material including (but not limited to) non-commercial research and private study; text and data mining for non-commercial research; criticism, review, and reporting current events; teaching; accessibility; time-shifting; and parody, caricature, and pastiche. Further information is available from the UK <a href="https://www.gov.uk/guidance/exceptions-to-copyright" class="link" target="_blank">Intellectual Property Office</a>.</p>
                            </div>
                            <div class="text-block" id="tos-8">
                                <h2>8. Links to Our Site</h2>
                                <p>8.1 You may link to any page on Our Site.</p>
                                <p>8.2 Links to Our Site must be fair and lawful. You must not take unfair advantage of Our reputation or attempt to damage Our reputation.</p>
                                <p>8.3 You must not link to Our Site in a manner that suggests any association with Us (where there is none) or any endorsement or approval from Us (where there is none).</p>
                                <p>8.4 Your link should not use any logos or trade marks displayed on Our Site without Our express written permission.</p>
                                <p>8.5 You must not frame or embed Our Site on another website without Our express written permission.</p>
                                <p>8.6 You may not link to Our Site from another website the main content of which is unlawful; obscene; offensive; inappropriate; dishonest; defamatory; threatening; racist, sexist, or otherwise discriminatory; that promotes violence, racial hatred, or terrorism; that infringes intellectual property rights; or that We deem to be otherwise objectionable.</p>
                            </div>
                            <div class="text-block" id="tos-9">
                                <h2>9. Links to Other Sites</h2>
                                <p>9.1 Links to other websites may be included on Our Site. Unless expressly stated, these sites are not under Our control. We accept no responsibility or liability for the content of third-party websites.</p>
                                <p>9.2 The inclusion of a link to another website on Our Site is for information purposes only and does not imply any endorsement of that website or of its owners, operators, or any other parties involved with it.</p>
                            </div>
                            <div class="text-block" id="tos-10">
                                <h2>10. Disclaimers</h2>
                                <p>10.1	Nothing on Our Site constitutes professional advice on which you should rely. It is provided for general information purposes only.</p>
                                <p>10.2	We make reasonable efforts to ensure that the Content on Our Site is complete, accurate, and up to date, but We make no warranties, representations, or guarantees (express or implied) that this will always be the case.</p>
                                <p>10.3	If you are a business user, We exclude all implied representations, warranties, conditions, and other terms that may apply to Our Site and Content.</p>
                            </div>
                            <div class="text-block" id="tos-11">
                                <h2>11. Our Liability</h2>
                                <p>11.1	Nothing in these Terms and Conditions excludes or restricts Our liability for fraud or fraudulent misrepresentation, for death or personal injury resulting from negligence, or for any other forms of liability which cannot be lawfully excluded or restricted.</p>
                                <p>11.2	If you are a business user (i.e. you are using Our Site in the course of business or for commercial purposes), to the fullest extent permissible by law, We accept no liability for any loss or damage, whether foreseeable or otherwise, in contract, tort (including negligence), for breach of statutory duty, or otherwise, arising out of or in connection with the use of (or inability to use) Our Site or the use of or reliance upon any Content included on Our Site.</p>
                                <p>11.3	If you are a business user, We accept no liability for loss of profit, sales, business, or revenue; loss of business opportunity, goodwill, or reputation; loss of anticipated savings; business interruption; or for any indirect or consequential loss or damage.</p>
                                <p>11.4	If you are a consumer and digital content from Our Site damages other digital content or a device belonging to you, where that damage is caused by Our failure to use reasonable skill and care, We will either compensate you or repair the damage.</p>
                            </div>
                            <div class="text-block" id="tos-12">
                                <h2>12. Viruses, Malware and Security</h2>
                                <p>12.1	We exercise reasonable skill and care to ensure that Our Site is secure and free from viruses and malware; however, We do not guarantee that this is the case.</p>
                                <p>12.2	You are responsible for protecting your hardware, software, data, and other material from viruses, malware, and other internet security risks.</p>
                                <p>12.3	You must not deliberately introduce viruses or other malware, or any other material which is malicious or technologically harmful either to or via Our Site.</p>
                                <p>12.4	You must not attempt to gain unauthorised access to any part of Our Site, the server on which Our Site is stored, or any other server, computer, or database connected to Our Site.</p>
                                <p>12.5	You must not attack Our Site by means of a denial of service attack, a distributed denial of service attack, or by any other means.</p>
                                <p>12.6	By breaching the provisions of Parts 12.3 to 12.5, you may be committing a criminal offence under the Computer Misuse Act 1990. Any and all such breaches will be reported to the relevant law enforcement authorities and We will cooperate fully with those authorities by disclosing your identity to them. Your right to use Our Site will cease immediately in the event of such a breach.</p>
                            </div>
                            <div class="text-block" id="tos-13">
                                <h2>13. Acceptable Usage Policy</h2>
                                <p>
                                    13.1 You may only use Our Site in a lawful manner:
                                    <ol type="a">
                                        <li>You must ensure that you comply fully with any and all local, national, or international laws and regulations that apply;</li>
                                        <li>You must not use Our site in any way, or for any purpose, that is unlawful or fraudulent; and</li>
                                        <li>You must not use Our Site to knowingly send, upload, or in any other way transmit data that contains any form of virus or other malware or any other code designed to adversely affect computer hardware, software, or data of any kind.</li>
                                    </ol>
                                </p>
                                <p>
                                    13.2 If you fail to comply with the provisions of this Part 13, you will be in breach of these Terms and Conditions. We may take one or more of the following actions in response:
                                    <ol type="a">
                                        <li>Suspend or terminate your right to use Our Site;</li>
                                        <li>Issue you with a written warning;</li>
                                        <li>Take legal proceedings against you for reimbursement of any and all relevant costs on an indemnity basis resulting from your breach;</li>
                                        <li>Take further legal action against you, as appropriate;</li>
                                        <li>Disclose such information to law enforcement authorities as required or as We deem reasonably necessary; and/or</li>
                                        <li>Any other actions which We deem reasonably appropriate (and lawful).</li>
                                    </ol>
                                </p>
                                <p>13.3	We hereby exclude any and all liability arising out of any actions that We may take (including, but not limited to those set out above in Part 13.2) in response to your breach.</p>
                            </div>
                            <div class="text-block" id="tos-14">
                                <h2>14.	How We Use Your Personal Information</h2>
                                <p>We will only use your personal information as set out in Our <span class="link pointer" @click="SetLegalItemActiveState('privacy-policy')">Privacy Policy</span>.</p>
                            </div>
                            <div class="text-block" id="tos-15">
                                <h2>15. Communications from Us</h2>
                                <p>15.1	If We have your contact details, We may send you important notices by email from time to time. Such notices may relate to matters including, but not limited to, changes to Our Site or to these Terms and Conditions.</p>
                                <p>15.2	We will not send you marketing emails without your express consent. If you do consent to marketing, you may opt out at any time. All marketing emails from Us include an unsubscribe link. If you opt out of emails from Us, it may take up to 72 hours for your request to take effect and you may continue to receive emails during that time.</p>
                                <p>15.3	For questions or complaints about communications from Us, please contact Us using the details above in <a href="#tos-3" class="link">Part 3</a>.</p>
                            </div>
                            <div class="text-block" id="tos-16">
                                <h2>16.	Law and Jurisdiction</h2>
                                <p>16.1	These Terms and Conditions, and the relationship between you and Us (whether contractual or otherwise) shall be governed by, and construed in accordance with, English law.</p>
                                <p>16.2	If you are a consumer, you will benefit from any mandatory provisions of the law in your country of residence. Nothing in Part 16.1 takes away from or reduces your legal rights as a consumer.</p>
                                <p>16.3	If you are a consumer, any dispute, controversy, proceedings, or claim between you and Us relating to these Terms and Conditions or to the relationship between you and Us (whether contractual or otherwise) shall be subject to the jurisdiction of the courts of England, Wales, Scotland, or Northern Ireland, as determined by your residency.</p>
                                <p>16.4	If you are a business user, any dispute, controversy, proceedings, or claim between you and Us relating to these Terms and Conditions or to the relationship between you and Us (whether contractual or otherwise) shall be subject to the exclusive jurisdiction of the courts of England and Wales.</p>
                            </div>
                        </div>
                        <!-- Privacy Policy -->
                        <div class="legal-item" v-show="showPrivacyPolicy">
                            <div class="flex download-modified-wrapper">
                                <div class="icon-with-text pointer" id="download-pdf" @click.prevent="DownloadPDF('Privacy Policy May 19 2022.pdf')">
                                    <i class="bi bi-download"></i>
                                    Download PDF
                                </div>
                                <div class="icon-with-text" id="last-modified">
                                    <i class="bi bi-calendar"></i>
                                    Last Modified: May 19 2022
                                </div>
                            </div>
                            <h1>Privacy Policy</h1>
                            <div class="text-block">
                                <h2>Background</h2>
                                <p>Illapa Cloud Limited understands that your privacy is important to you and that you care about how your personal data is used. We respect and value the privacy of everyone who visits this website, <a href="https://illapa.cloud" class="link" target="_blank">https://illapa.cloud</a> (“Our Site”) and will only collect and use personal data in ways that are described here, and in a way that is consistent with our obligations and your rights under the law.</p>
                                <p>Please read this Privacy Policy carefully and ensure that you understand it. Your acceptance of this Privacy Policy is requested <a href="https://illapa.cloud/platform/poc-trial" class="link" target="_blank">https://illapa.cloud/platform/poc-trial</a> and <a href="https://illapa.cloud/company/contact-us" class="link" target="_blank">https://illapa.cloud/company/contact-us</a>.</p>
                            </div>
                            <div class="text-block" id="privacy-1">
                                <h2>1. Information About Us</h2>
                                <p>Our Site is owned and operated by Illapa Cloud Limited, a limited company  registered in England under company number 10890314.</p>
                                <p>Registered address: 11 Castle Hill, Maidenhead, Berkshire SL6 4AA.</p>
                                <p>Address: 1st Floor, Building 3, Concorde Park, Concorde Road, Maidenhead, Berskhire SL6 4BY.</p>
                                <p>VAT number: 274 4676 70.</p>
                            </div>
                            <div class="text-block" id="privacy-2">
                                <h2>2. What Does This Policy Cover</h2>
                                <p>This Privacy Policy applies only to your use of Our Site. Our Site may contain links to other websites. Please note that we have no control over how your data is collected, stored, or used by other websites and we advise you to check the privacy policies of any such websites before providing any data to them.</p>
                            </div>
                            <div class="text-block" id="privacy-3">
                                <h2>3. What Is Personal Data?</h2>
                                <p>Personal data is defined by the UK GDPR and the Data Protection Act 2018 (collectively, “the Data Protection Legislation”) as ‘any information relating to an identifiable person who can be directly or indirectly identified in particular by reference to an identifier’.</p>
                                <p>Personal data is, in simpler terms, any information about you that enables you to be identified. Personal data covers obvious information such as your name and contact details, but it also covers less obvious information such as identification numbers, electronic location data, and other online identifiers.</p>
                            </div>
                            <div class="text-block" id="privacy-4">
                                <h2>4. What Are My Rights?</h2>
                                <p>
                                    Under the Data Protection Legislation, you have the following rights, which we will always work to uphold:
                                    <ol type="a">
                                        <li>The right to be informed about our collection and use of your personal data. This Privacy Policy should tell you everything you need to know, but you can always contact us to find out more or to ask any questions using the details in <a href="#privacy-12" class="link">Part 12</a>.</li>
                                        <li>The right to access the personal data we hold about you. <a href="#privacy-11" class="link">Part 11</a> will tell you how to do this.</li>
                                        <li>The right to have your personal data rectified if any of your personal data held by us is inaccurate or incomplete. Please contact us using the details in <a href="#privacy-12" class="link">Part 12</a> to find out more.</li>
                                        <li>The right to be forgotten, i.e. the right to ask us to delete or otherwise dispose of any of your personal data that we hold. Please contact us using the details in <a href="#privacy-12" class="link">Part 12</a> to find out more.</li>
                                        <li>The right to restrict (i.e. prevent) the processing of your personal data.</li>
                                        <li>The right to object to us using your personal data for a particular purpose or purposes.</li>
                                        <li>The right to withdraw consent. This means that, if we are relying on your consent as the legal basis for using your personal data, you are free to withdraw that consent at any time.</li>
                                        <li>The right to data portability. This means that, if you have provided personal data to us directly, we are using it with your consent or for the performance of a contract, and that data is processed using automated means, you can ask us for a copy of that personal data to re-use with another service or business in many cases.</li>
                                        <li>Rights relating to automated decision-making and profiling. We do not use your personal data in this way.</li>
                                    </ol>
                                </p>
                                <p>For more information about our use of your personal data or exercising your rights as outlined above, please contact us using the details provided in <a href="#privacy-12" class="link">Part 12</a>.</p>
                                <p>It is important that your personal data is kept accurate and up-to-date. If any of the personal data we hold about you changes, please keep us informed as long as we have that data.</p>
                                <p>Further information about your rights can also be obtained from the Information Commissioner’s Office or your local Citizens Advice Bureau.</p>
                                <p>If you have any cause for complaint about our use of your personal data, you have the right to lodge a complaint with the Information Commissioner’s Office. We would welcome the opportunity to resolve your concerns ourselves however, so please contact us first, using the details in <a href="#privacy-12" class="link">Part 12</a>.</p>
                            </div>
                            <div class="text-block" id="privacy-5">
                                <h2>5. What Data Do You Collect and How?</h2>
                                <p>Depending upon your use of our Site, we may collect and hold some or all of the personal and non-personal data set out in the table below, using the methods also set out in the table.</p>
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>Data Collected</th>
                                            <th>How We Collect The Data</th>
                                        </tr>
                                        <tr>
                                            <td>Identity Information including name and title</td>
                                            <td>Form input from our website</td>
                                        </tr>
                                        <tr>
                                            <td>Contact information including address, email address and mobile telephone number</td>
                                            <td>Form input from our website</td>
                                        </tr>
                                        <tr>
                                            <td>Business information including business name and job title</td>
                                            <td>Form input from our website</td>
                                        </tr>
                                        <tr>
                                            <td>Technical information including infrastructure vendors</td>
                                            <td>Form input from our website</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="text-block" id="privacy-6">
                                <h2>6. How Do You Use My Personal Data?</h2>
                                <p>Under the Data Protection Legislation, we must always have a lawful basis for using personal data. The following table describes how we will use your personal data, and our lawful bases for doing so:</p>
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>What We Do</th>
                                            <th>What Data We Use</th>
                                            <th>Our Lawful Basis</th>
                                        </tr>
                                        <tr>
                                            <td>Personalising and tailoring our products and services for you</td>
                                            <td>Identity, Contact, Business, Technical</td>
                                            <td>“legitimate interest” Request from completion of  POC Trial form</td>
                                        </tr>
                                        <tr>
                                            <td>Communicating with you</td>
                                            <td>Identity, Contact</td>
                                            <td>“legitimate interest” Request from completion of Contact Us form</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>With your permission and/or where permitted by law, we may also use your personal data for marketing purposes, which may include contacting you by email, telephone, text message and post with information, news, and offers on our products and services. You will not be sent any unlawful marketing or spam. We will always work to fully protect your rights and comply with our obligations under the Data Protection Legislation and the Privacy and Electronic Communications (EC Directive) Regulations 2003, and you will always have the opportunity to opt-out. We will always obtain your express opt-in consent before sharing your personal data with third parties for marketing purposes and you will be able to opt-out at any time.</p>
                                <p>We will only use your personal data for the purpose(s) for which it was originally collected unless we reasonably believe that another purpose is compatible with that or those original purpose(s) and need to use your personal data for that purpose. If we do use your personal data in this way and you wish us to explain how the new purpose is compatible with the original, please contact us using the details in <a href="#privacy-12" class="link">Part 12</a>.</p>
                                <p>If we need to use your personal data for a purpose that is unrelated to, or incompatible with, the purpose(s) for which it was originally collected, we will inform you and explain the legal basis which allows us to do so.</p>
                                <p>In some circumstances, where permitted or required by law, we may process your personal data without your knowledge or consent. This will only be done within the bounds of the Data Protection Legislation and your legal rights.</p>
                            </div>
                            <div class="text-block" id="privacy-7">
                                <h2>7. How Long Will You Keep My Personal Data?</h2>
                                <p>We will not keep your personal data for any longer than is necessary in light of the reason(s) for which it was first collected. Your personal data will therefore be kept for the following periods (or, where there is no fixed period, the following factors will be used to determine how long it is kept):</p>
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>Type of Data</th>
                                            <th>How Long We Keep It</th>
                                        </tr>
                                        <tr>
                                            <td>Identity Information including name and title</td>
                                            <td>Six Years.</td>
                                        </tr>
                                        <tr>
                                            <td>Contact information including address, email address and mobile telephone number</td>
                                            <td>Six Years.</td>
                                        </tr>
                                        <tr>
                                            <td>Business information including business name and job title</td>
                                            <td>Six Years.</td>
                                        </tr>
                                        <tr>
                                            <td>Technical information including infrastructure vendors</td>
                                            <td>Six Years.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="text-block" id="privacy-8">
                                <h2>8. How and Where Do You Store or Transfer My Personal Data?</h2>
                                <p>We may store some or all of your personal data in countries outside of the UK. These are known as “third countries”. We will take additional steps in order to ensure that your personal data is treated just as safely and securely as it would be within the UK and under the Data Protection Legislation as follows:</p>
                                <p>We will only store or transfer personal data in or to countries that are deemed to provide an adequate level of protection for personal data. For further information about adequacy decisions and adequacy regulations, please refer to the <a href="https://ico.org.uk/" class="link" target="_blank">Information Commissioner’s Office</a>.</p>
                                <p>Please contact us using the details below in <a href="#privacy-12">Part 12</a> for further information about the particular data protection safeguards used by us when transferring your personal data to a third country.</p>
                                <p>
                                    The security of your personal data is essential to us, and to protect your data, we take a number of important measures, including the following:
                                    <ul>
                                        <li>limiting access to your personal data to those employees, agents, contractors, and other third parties with a legitimate need to know and ensuring that they are subject to duties of confidentiality;</li>
                                        <li>procedures for dealing with data breaches (the accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to, your personal data) including notifying you and/or the Information Commissioner’s Office where we are legally required to do so;</li>
                                    </ul>
                                </p>
                            </div>
                            <div class="text-block" id="privacy-9">
                                <h2>9. Do You Share My Personal Data?</h2>
                                <p>We will not share any of your personal data with any third parties for any purposes, subject to the following exceptions.</p>
                                <p>If we sell, transfer, or merge parts of our business or assets, your personal data may be transferred to a third party. Any new owner of our business may continue to use your personal data in the same way(s) that we have used it, as specified in this Privacy Policy.</p>
                                <p>In some limited circumstances, we may be legally required to share certain personal data, which might include yours, if we are involved in legal proceedings or complying with legal obligations, a court order, or the instructions of a government authority.</p>
                                <p>We may sometimes contract with the following third parties to supply certain products services.</p>
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>Recipient</th>
                                            <th>Activity Carried Out</th>
                                            <th>Sector</th>
                                            <th>Location</th>
                                        </tr>
                                        <tr>
                                            <td>Morpheus Data</td>
                                            <td>Cloud and Policy Management</td>
                                            <td>Software</td>
                                            <td>USA</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>If any of your personal data is shared with a third party, as described above, we will take steps to ensure that your personal data is handled safely, securely, and in accordance with your rights, our obligations, and the third party’s obligations under the law, as described above in <a href="#privacy-8" class="link">Part 8</a>.</p>
                                <p>If any personal data is transferred outside of the UK, we will take suitable steps in order to ensure that your personal data is treated just as safely and securely as it would be within the UK and under the Data Protection Legislation, as explained above in <a href="#privacy-8" class="link">Part 8</a>.</p>
                            </div>
                            <div class="text-block" id="privacy-10">
                                <h2>10. Can I Withhold Information?</h2>
                                <p>You may access certain areas of Our Site without providing any personal data at all. However, to use all features and functions available on Our Site you may be required to submit or allow for the collection of certain data.</p>
                            </div>
                            <div class="text-block" id="privacy-11">
                                <h2>11. How Can I Access My Personal Data?</h2>
                                <p>If you want to know what personal data we have about you, you can ask us for details of that personal data and for a copy of it (where any such personal data is held). This is known as a “subject access request”.</p>
                                <p>All subject access requests should be made in writing and sent to the email or postal addresses shown in <a href="#privacy-12" class="link">Part 12</a>.</p>
                                <p>There is not normally any charge for a subject access request. If your request is ‘manifestly unfounded or excessive’ (for example, if you make repetitive requests) a fee may be charged to cover our administrative costs in responding.</p>
                                <p>We will respond to your subject access request, not more than one month of receiving it. Normally, we aim to provide a complete response, including a copy of your personal data within that time. In some cases, however, particularly if your request is more complex, more time may be required up to a maximum of three months from the date we receive your request. You will be kept fully informed of our progress.</p>
                            </div>
                            <div class="text-block" id="privacy-12">
                                <h2>12. How Do I Contact You?</h2>
                                <p>To contact Us about anything to do with your personal data and data protection, including to make a subject access request, please use the following details:</p>
                                <p>Email address: <a href="mailto:info@illapa.cloud" class="link">info@illapa.cloud</a></p>
                                <p>Telephone number: <a href="tel:+44 (0)203 917 1110" class="link">+44 (0) 203 917 1110</a></p>
                                <p>Postal address: 1st Floor, Building 3, Concorde Park, Concorde Road, Maidenhead, Berkshire. SL6 4BY</p>
                            </div>
                            <div class="text-block" id="privacy-13">
                                <h2>13. Changes to this Privacy Policy</h2>
                                <p>We may change this Privacy Policy from time to time. This may be necessary, for example, if the law changes, or if we change our business in a way that affects personal data protection.</p>
                                <p>Any changes will be immediately posted on Our Site and you will be deemed to have accepted the terms of the Privacy Policy on your first use of Our Site following the alterations. We recommend that you check this page regularly to keep up-to-date. This Privacy Policy was last updated on 19th May 2022.</p>
                            </div>
                        </div>
                        <!-- Cookie Policy -->
                        <!-- <div class="legal-item" v-show="showCookiePolicy">
                            <div class="flex align-center">
                                <div class="icon-with-text flex align-center pointer">
                                    <i class="bi bi-download"></i>
                                    Download PDF
                                </div>
                                <div class="icon-with-text flex align-center ml-auto">
                                    <i class="bi bi-calendar"></i>
                                    Last Modified: January 1 2020
                                </div>
                            </div>
                            <h1>Cookie Policy</h1>
                            <div class="text-block">
                                <h2>Introduction</h2>
                                <p>Cookies are small text files that are stored by the browser (for example, internet explorer or safari) on your computer or mobile phone. they allow websites to store things like user preferences. you can think of cookies as providing a ‘memory’ for the website, so that it can recognise you when you come back and respond appropriately.</p>
                                <p>This page explains how we use cookies and other similar technologies to help us ensure that our services function properly, prevent fraud and other harm, and analyse and improve the services in accordance with our <span class="link pointer" @click="SetLegalItemActiveState('privacy-policy')">Privacy Policy</span>.</p>
                            </div>
                            <div class="text-block">
                                <h2>How We Use Cookies</h2>
                                <p>Cookies play an important role in helping us provide personal, effective and safe services. we use cookies on our website. we change the cookies periodically as we improve or add to our services, but we generally use cookies for the following purposes:</p>
                                <ol>
                                    <li>
                                        <p>to operate our services. some cookies are essential to the operation of our website. we use those cookies in a number of different ways, including:</p>
                                        <ul>
                                            <li>authentication. we use cookies to remember your login state so you don’t have to login as you navigate through our site and your dashboard.</li>
                                            <li>fraud prevention and detection. cookies and similar technologies that we deploy through websites help us learn things about computers and web browsers used. this information helps us monitor for and detect potentially harmful or illegal use of our services.</li>
                                            <li>site features. we use cookies to remember how you prefer to use our website so that you don’t have to reconfigure your settings each time you log into your account.</li>
                                        </ul>
                                    </li>
                                    <li>to analyse and improve our services. cookies help us understand how to make our website work better for you. cookies tell us how people reach our website and they give us insights into improvements or enhancements we need to make.</li>
                                    <li>for better advertising. cookies can help us provide more effective advertising on our website. for example, we might use a cookie to help prevent you from seeing the same advertisement multiple times or to measure how many times an advertisement is viewed or clicked on.</li>
                                </ol>
                            </div>
                            <div class="text-block">
                                <h2>Types of Cookies We Use</h2>
                                <ol>
                                    <li>pixel tags. pixel tags (also known as web beacons and clear gifs) may be used in connection with some services to, among other things, track the actions of users (such as email recipients), measure the success of our marketing campaigns and compile statistics about usage of the services and response rates.</li>
                                    <li>third party analytics. we use google analytics, which uses cookies and similar technologies, to collect and analyse information about use of the services and report on activities and trends. this service may also collect information regarding the use of other websites, apps and online resources. you can learn about Google’s practices on the <a href="https://www.google.com/policies/privacy/partners/" class="link" target="_blank">Google website</a>. please refer to the table below for more on how we use third party analytics.</li>
                                </ol>
                            </div>
                            <div class="text-block">
                                <h2>Taking Control of Your Cookies</h2>
                                <p>It is usually possible to stop your browser accepting cookies, or to stop it accepting cookies from a particular website.</p>
                                <p>All modern browsers allow you to change your cookie settings. you can usually find these settings in the options or preferences menu of your browser. To understand these settings, the following links may be helpful, or you can use the help option in your browser for more details.</p>
                                <p>
                                    Chrome:
                                    <a href="https://support.google.com/chrome/answer/95647?hl=en" class="link" target="_blank">
                                        https://support.google.com/chrome/answer/95647?hl=en
                                    </a>
                                </p>
                                <p>
                                    Explorer:
                                    <a href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d" class="link" target="_blank">
                                        https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d
                                    </a>
                                </p>
                                <p>
                                    Safari:
                                    <a href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac" class="link" target="_blank">
                                        https://support.apple.com/en-gb/guide/safari/sfri11471/mac
                                    </a>
                                </p>
                                <p>
                                    Firefox:
                                    <a href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox" class="link" target="_blank">
                                        https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox
                                    </a>
                                </p>
                                <p>
                                    Opera:
                                    <a href="https://www.opera.com/use-cases/clean-browser-and-remove-trackers" class="link" target="_blank">
                                        https://www.opera.com/use-cases/clean-browser-and-remove-trackers
                                    </a>
                                </p>
                                <p>Please note, if you choose to disable the cookies, some features of our website or services may not operate as intended.</p>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Header from "../components/header/Header.vue";

export default {
    components: {
        Header
    },
    metaInfo() {
        return {
            title: `${this.metaInfoTitle} | Legal | Illapa Cloud`,
            meta: [
                {
                    name: 'description',
                    content: 'By Using Our Site You Accept These Terms and Conditions. Please read these Terms and Conditions carefully and ensure that you understand them before using Our Site. These Terms and Conditions, together with any other documents referred to herein, set out the terms of use governing your use of this website, https://illapa.cloud (“Our Site”). It is recommended that you print a copy of these Terms and Conditions for your future reference.'
                },
                {
                    property: 'og:title',
                    content: `${this.metaInfoTitle} | Legal | Illapa Cloud`
                },
                {
                    property: 'og:description',
                    content: 'By Using Our Site You Accept These Terms and Conditions. Please read these Terms and Conditions carefully and ensure that you understand them before using Our Site. These Terms and Conditions, together with any other documents referred to herein, set out the terms of use governing your use of this website, https://illapa.cloud (“Our Site”). It is recommended that you print a copy of these Terms and Conditions for your future reference.'
                },
                {
                    property: 'og:url',
                    content: 'https://illapa.cloud/legal'
                }
            ],
            link: [
                {
                    rel: 'canonical',
                    href: 'https://illapa.cloud/legal'
                }
            ]
        }
    },
    data: () => ({
        activeLegalItem: "",
        metaInfoTitle: "",
        showTermsOfService: false, showPrivacyPolicy: false,
    }),
    created() {
        if (this.$route.query.legal == undefined) {
            this.activeLegalItem = 'terms-of-service';
        } else {
            this.activeLegalItem = this.$route.query.legal;
        }
    },
    watch: {
        activeLegalItem: function() {
            this.ResetLegalItemActiveStates();
            switch(this.activeLegalItem) {
                case "terms-of-service":
                    this.showTermsOfService = true;
                    this.metaInfoTitle = 'Terms of Service';
                    break;
                case "privacy-policy":
                    this.showPrivacyPolicy = true;
                    this.metaInfoTitle = 'Privacy Policy';
                    break;
                default:
                    break;
            }
            this.ScrollToTop();
        },
        $route: function () {
            if (this.$route.query.legal == undefined) {
                this.activeLegalItem = 'terms-of-service';
            } else {
                this.activeLegalItem = this.$route.query.legal;
            }
        }
    },
    methods: {
        ResetLegalItemActiveStates() {
            this.showTermsOfService = false;
            this.showPrivacyPolicy = false;
        },
        SetLegalItemActiveState(legalItem) {
            this.activeLegalItem = legalItem;
        },
        ScrollToTop() {
            window.scroll(0,0);
        },
        DownloadPDF(url) {
            let link = document.createElement('a');
            if (link.download !== undefined) {
                link.setAttribute('href', `/${url}`);
                link.setAttribute('download', `${url}`);
                link.setAttribute('target', "_blank");
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        },
    },
}
</script>

<style scoped>
.legal-wrapper .legal-menu {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    max-width: 550px;
    margin: 0 auto 30px auto;
    padding-top: 100px;
}
.legal-wrapper .legal-menu .menu-item {
    position: relative;
    padding: 20px 0;
    font-size: 18px;
    font-weight: 300;
    color: var(--text-dark-secondary);
    user-select: none;
    cursor: pointer;
    transition: color 0.2s ease,
                transform 0.2s ease;
}
.legal-wrapper .legal-menu .menu-item:hover,
.legal-wrapper .legal-menu .menu-item.active {
    color: var(--text-dark-primary);
    transform: translateY(-5px);
}
.legal-wrapper .legal-menu .menu-item::after {
    content: "";
    position: absolute;
    bottom: 8px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 0%;
    height: 2px;
    background-color: var(--brand-primary-blue);
    transition: width 0.2s ease;
}
.legal-wrapper .legal-menu .menu-item.active::after {
    width: 100%;
}
.legal-wrapper .legal-content-wrapper {
    margin: 0 auto 100px auto;
    padding: 64px;
    border-radius: 12px 12px 0 0;
    box-shadow: 0 6px 30px 0 rgba(0,0,0,0.1);
    text-align: left;
}
/* Legal Items (Content) */
.legal-wrapper .legal-content-wrapper .legal-item .text-block {
    margin-bottom: 60px;
}
.legal-wrapper .legal-content-wrapper .legal-item h1 {
    font-weight: 500;
}
.legal-wrapper .legal-content-wrapper .legal-item h4 {
    color: var(--dark-default);
    font-weight: 400;
    margin-bottom: 1rem;
    margin-top: 2rem;
}
.legal-wrapper .legal-content-wrapper .legal-item p,
.legal-wrapper .legal-content-wrapper .legal-item ul,
.legal-wrapper .legal-content-wrapper .legal-item ol {
    font-weight: 300;
    line-height: 1.8;
    margin: 0;
    margin-bottom: 1rem;
    font-size: 18px;
    text-align: justify;
}
.legal-wrapper .legal-content-wrapper .legal-item table {
    margin: 0;
    margin-bottom: 1rem;
    font-size: 18px;
    line-height: 1.8;
    font-weight: 300;
    border-bottom: 2px solid #eee;
    width: 100%;
}
.legal-wrapper .legal-content-wrapper .legal-item table tr {
    border-bottom: 1px solid #ddd;
}
.legal-wrapper .legal-content-wrapper .legal-item table tr:nth-child(2n+1) {
    background-color: white;
}
.legal-wrapper .legal-content-wrapper .legal-item table tr:nth-child(2n) {
    background-color: #E7E9EB;
}
.legal-wrapper .legal-content-wrapper .legal-item table tr:nth-child(1) {
    background-color: var(--dark-default);
    color: white;
}
.legal-wrapper .legal-content-wrapper .legal-item table th {
    text-align: left;
}
.legal-wrapper .legal-content-wrapper .legal-item table th,
.legal-wrapper .legal-content-wrapper .legal-item table td {
    padding: 8px;
}
.legal-wrapper .legal-content-wrapper .legal-item .link {
    color: var(--brand-accent-blue);
}
.legal-wrapper .legal-content-wrapper .legal-item .icon-with-text {
    margin-bottom: 40px;
    font-size: 18px;
    display: inline-block;
    color: var(--text-dark-secondary);
}
.legal-wrapper .legal-content-wrapper .legal-item .icon-with-text i {
    color: var(--brand-accent-blue);
    margin-right: 10px;
    font-size: 22px;
}
@media only screen and (max-width: 600px) {
    .legal-section .container {
        padding: 0 !important;
    }
    .legal-wrapper .legal-content-wrapper {
        border-radius: 0;
        padding: 20px;
    }
    .download-modified-wrapper {
        flex-direction: column;
    }
}
@media only screen and (min-width: 600px) {
    .legal-wrapper .legal-content-wrapper {
        padding: 40px;
    }
    #last-modified {
        margin-left: auto;
    }
    .download-modified-wrapper {
        align-items: center;
    }
}
@media only screen and (min-width: 600px) and (max-width: 1024px) {
    .legal-section .container {
        padding: 0 20px !important;
    }
}
@media only screen and (min-width: 1024px) {
    .legal-section .container {
        padding: 0 60px !important;
    }
    .legal-wrapper .legal-content-wrapper {
        padding: 64px;
    }
}
</style>